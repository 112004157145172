<template>
  <v-dialog v-model="visible" fullscreen>
    <div class="content-bg">
      <div id="contentId" class="px-4 content" @scroll="scrollHandler">
        <v-btn
          fab
          class="my-auto  minor--text close"
          icon
          @click="visible = false"
          ><v-icon size="30">icon-close2 </v-icon></v-btn
        >
        <v-img
          class="mx-auto"
          style="margin-top:70px"
          max-width="154"
          src="@/assets/img_hy_icon_text.png"
        ></v-img>
        <v-text-field
          hide-details
          v-model="searchText"
          color="minor"
          rounded
          placeholder="搜索"
          autofocus
          class="search-text mx-auto  f16"
        >
          <v-icon slot="prepend-inner" size="18px" color="info"
            >icon-search</v-icon
          >
        </v-text-field>
        <v-list class="pa-0 list" v-if="books.length">
          <v-list-item
            class="item"
            v-for="book in books"
            :key="book.id"
            @click="itemClickHandler(book.id)"
          >
            <c-img-book-cover
              :src="resHost + book.cover"
              max-width="118"
              max-height="156"
              width="118"
              height="156"
              class="my-auto ml-2"
              :title="book.name"
            ></c-img-book-cover>
            <div class="text-content">
              <v-clamp
                class="common--text font-weight-medium f20"
                autoresize
                :max-lines="2"
                >{{ book.name }}</v-clamp
              >
              <v-clamp autoresize :max-lines="1" class="mt-4 f16 body--text">{{
                book.author
              }}</v-clamp>
            </div>
          </v-list-item>
        </v-list>
        <infinite-loading
          v-if="books.length > 0"
          ref="infiniteLoading"
          :distance="10"
          @infinite="infiniteHandler"
          spinner="circles"
        >
          <div slot="no-more">
            <p class="f16 minor--text mt-6">更多内容,敬请期待～</p>
          </div>
        </infinite-loading>
      </div>
    </div>
    <v-btn
      fab
      absolute
      class="minor--text top-btn white"
      icon
      elevation="1"
      @click="contentScrollTop"
      v-if="showTopBtn"
      ><v-icon size="30">mdi-arrow-up</v-icon></v-btn
    >
  </v-dialog>
</template>
<script>
import { getBookSearchList } from "@/api/api-pc.js";
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
import VClamp from "vue-clamp";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
      books: [],
      page: 0,
      size: 10,
      needLoadMore: true,
      isFirst: true,
      scrollOffSet: 0,
    };
  },
  methods: {
    scrollHandler(e) {
      this.scrollOffSet = e.target.scrollTop;
    },
    contentScrollTop() {
      document.getElementById("contentId").scroll(0, 0);
    },
    infiniteHandler($state) {
      this.page++;
      getBookSearchList(this.page, this.size, this.searchText).then((res) => {
        console.log("infiniteHandler", this.page);
        let tempData = res.data;
        this.needLoadMore = tempData.length > 0 ? true : false;
        $state.loaded();
        if (this.needLoadMore == false) {
          $state.complete();
        }
        this.books.push(...tempData);
        this.loading = false;
      });
    },
    itemClickHandler(bid) {
      this.$router.push({
        name: "pcBookDetail",
        params: {
          bookId: bid.toString(),
        },
      });

      this.$track.event({
        category: "book",
        name: "visit",
        value: bid.toString(),
        params: {
          referer_action: "search",
          tag: bid.toString(),
        },
      });
    },
  },
  watch: {
    searchText(val) {
      this.books = [];
      this.page = 1;
      if (val) {
        getBookSearchList(this.page, this.size, val).then((res) => {
          this.books = res.data;
        });
        this.$track.event({
          category: "tool",
          name: "search",
          value: val,
        });
      }
    },
    visible(val) {
      if (val) {
        this.searchText = "";
        this.books = [];
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    showTopBtn() {
      return this.scrollOffSet > 100;
    },
  },
  components: {
    CImgBookCover,
    VClamp,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: rgba(201, 201, 201, 0.5);
}

.content-bg {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.content {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

// .icon-img {
// position: sticky;
// top: 30px;
// }

.close {
  position: sticky;
  top: 0;
  left: 100%;
  min-height: 90px;
  height: 90px;
  z-index: 101;
}

.top-btn {
  position: absolute;
  top: 70%;
  right: 12%;
}

.search-text {
  // position: sticky;
  // top: 110px;
  max-width: 796px;
  height: 50px;
  background: white;
  margin-top: 50px;
  box-shadow: 0px 1px 12px 0px rgba(171, 171, 171, 0.2);
  z-index: 100;
}

::v-deep .v-text-field input {
  padding: 2.5px;
}

.list {
  margin: auto;
  margin-top: 50px;
  max-width: 796px;
}

.item {
  width: 100%;
  height: 205px;
  background: linear-gradient(
    270deg,
    rgba(251, 252, 253, 0.2) 0%,
    rgba(201, 201, 201, 0.2) 100%
  );
  border-radius: 6px;
}

.text-content {
  height: 100%;
  width: 100%;
  margin: 52px 0px 0px 30px;
}
</style>
